import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "date": "2017-05-30",
  "title": "What Turns Estimates Into Deadlines",
  "description": "Think before you share",
  "color": "#6a82ff"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Being stressed sucks. When we're stressed we don't enjoy enjoyable work. We don't learn much and we don't produce very creative work. When under stress we don't make great decisions. We put our physical & emotional health on the backburner with thoughts like "as soon things settle down I'll spend more time with family/friends" and "right after this I'll start going to the gym again". Activities and people that rejuvinate us get cut. This downhill path to burnout is increasingly unproductive. `}<strong parentName="p">{`A deadline is a tool for scheduling stress`}</strong>{` and should be removed from most software development. `}</p>
    <h2>{`Done With Deadlines`}</h2>
    <p>{`Deadlines are like bloated apps: they try to solve too many problems and do a pretty crappy job at all of them. Eric Elliott wrote a fantastic piece `}<a parentName="p" {...{
        "href": "https://medium.com/javascript-scene/why-deadlines-need-to-drop-dead-321739ae6be1"
      }}><em>{`Why Deadlines Need to Drop Dead`}</em></a>{` that describes all the problems they cause. At my last job I decided I was done with deadlines. It lead to some interesting experiences. I was the lead over a small team building out a new developer-facing product. Whenever my Product Manager would stop by and ask me when something was going to be done, I'd literally hand him a fortune cookie as my answer. Somehow we're still friends! And we built a fantastic product that our customers loved, in a reasonable time frame. All without deadlines.`}</p>
    <p>{`In January I quit my job to do my own thing. One day I was having lunch with some friends and they asked me how everything was going. I told them I was absolutely loving it but that I was stressed because I needed to get my first free course (`}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox Zombies`}</a>{`) shipped so I could move on to creating a paid thing, and that I had already announced a launch date that was going to be impossible to hit. One of them said "Does anyone else find it incredibly ironic that Dave gave himself a deadline?". He was so right it stung! I had gotten so excited about the launch of my Mastery Game that I had shared my estimate with my audience - and `}<strong parentName="p">{`by sharing it had turned it into a deadline`}</strong>{`. `}</p>
    <h2>{`An Estimate Shared is a Deadline`}</h2>
    <p>{`That's when I learned a valuable lesson. If you share an estimate, that makes it a deadline. Because now you have people counting on you `}<strong parentName="p">{`and`}</strong>{` a target date. As developers we take great pride in what we build, and want to please the people who value our skills. In fact I think the majority of us devs are natural people pleasers - which is what makes deadlines so harmful to most of us. Even if the people with whom you share your estimate are totally cool and don't mind if it has to get pushed back, that `}<strong parentName="p">{`pressure to not let them down is absolutely crushing`}</strong>{`. I was cutting my weekly Saturday Dad/kids date short. I was working late into the night most nights. Hadn't been to the gym in weeks. I started questioning if I was even cut out for it. I should have recognized the signs but it took my friend's brutally honest pointing of it out. I went straight home and removed the "Launching Early April" words from my site. I started giving my audience actual progress updates & behind the scenes looks rather than estimates/deadlines. Excitement for the big launch only increased.`}</p>
    <p>{`The only way to prevent an estimate from turning into a deadline is to not share it with anyone except maybe your dog who adores you and wants to lick your face no matter what you do. This is how Blizzard creates some of the best, most polished, lucrative games ever made. This is how I plan to build some of the best learning resources in the world for devs looking to master UI skills. So, you ask - when will my next course be out? I'm not telling! :)`}</p>
    <h2>{`Challenge`}</h2>
    <p>{`The next time you're asked for an estimate, try this: tell the person that you'd like to try an experiment where in place of sharing an estimate you share frequent progress updates of work completed and work remaining. Tell them you want to see if this will give them even more of the information that they need and keep them in the loop better, without some of the negative side effects that sharing estimates can sometimes bring. Follow it up by doing your very best work and by taking great care of yourself. And say goodbye to the dark days of deadlines.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      